<script setup lang="ts">

const awardsImage = [
  {
    name: 'certik',
    img: '/img/home/awards/certik.png'
  },
  {
    name: 'playearn-1',
    img: '/img/home/awards/playearn-1.png'
  },
  {
    name: 'skynet',
    img: '/img/home/awards/skynet.png'
  },
  {
    name: 'coinmarketcap',
    img: '/img/home/awards/coinmarketcap.png'
  },
  {
    name: 'playearn-2',
    img: '/img/home/awards/playearn-2.png'
  },
  {
    name: 'dorahacks',
    img: '/img/home/awards/dorahacks.png'
  }
]

</script>

<template>
  <div class="w-full bg-primary">
    <div class="container py-10 mx-auto">
      <div class="flex flex-col gap-6 justify-evenly">
        <div class="justify-center">
          <h1 class="mt-10 text-4xl font-bold text-center uppercase text-smoke-white">
            AWARDS & CREDENTIALS
          </h1>
        </div>

        <div class="flex-col items-center justify-center hidden gap-5 my-5 lg:flex">
          <div class="grid grid-cols-3">
            <div v-for="awards in awardsImage" :key="awards.name">
              <div class="flex flex-row">
                <div class="flex items-center justify-center">
                  <img :src="awards.img" class="scale-75">
                </div>
                <div v-if="awards.name !== 'skynet' && awards.name !=='dorahacks'" class="flex items-center justify-center">
                  <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 40 40" fill="none">
                    <rect
                      x="1.41421"
                      y="20"
                      width="26.2843"
                      height="26.2843"
                      transform="rotate(-45 1.41421 20)"
                      stroke="white"
                      stroke-width="2"
                    />
                    <rect
                      x="8.62476"
                      y="20"
                      width="16.0865"
                      height="16.0865"
                      transform="rotate(-45 8.62476 20)"
                      fill="#58D7FF"
                    />
                  </svg>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="flex flex-col items-center justify-center lg:hidden">
          <div v-for="awards in awardsImage" :key="awards.name">
            <img :src="awards.img" class="scale-75">
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
